:root {
  --primary-color: #36e2ec;
  /* --secondary-color: #609EC2; */
  --secondary-color: #50D2E6;
  --progress-color: #6C757D;
  /* --font-size: 16px;
  --border-radius: 5px; */
}
.text-darkmode-secondary{
  color: #36e2ec
}
.bg-darkmode-main{
  background: #0C1321;
}
.bg-darkmode-side{
  background: #0E1826;
}
.bg-darkmode-mic{
  background: #192a42
}

.tiny5-regular {
  font-family: "Tiny5", sans-serif;
  font-weight: 400;
  font-style: normal;
}


html, body, #root, .App {
  height: 100%;
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  display: flex;
}

.navbar-container, .content-container {
  height: 100%;
  overflow: auto;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mic-div {
  position: relative;
}

.mic-size {
  font-size: 15rem;
}

.mic-btn {
  background-color:#020307;
  width: 18rem; /* 设置按钮的宽度 */
  height: 18rem; /* 设置按钮的高度 */
  position: relative;
  z-index: 10;
}


.mic-btn:after,
.mic-btn:before {
  content: '';
  display: block;
  position: absolute;
  z-index: 0;
  /* background-color: rgba(136, 157, 168, 0.2); */
  background-color: rgba(14, 104, 148, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  /* transition: all 0.2s ease-in-out; 简化 transition 属性 */
}
.mic-btn:after{
  /* transition: 0.4s; */
  transition: 1s;
}
.mic-btn:before{
  /* transition: 0.4s; */
  transition: 1s;
}
.mic-btn:hover:before {
  width: calc(100% + 4rem); /* 动画效果的宽度 */
  height: calc(100% + 4rem); /* 动画效果的高度 */
  top: -2rem; /* 调整位置 */
  left: -2rem; /* 调整位置 */
  right: -2rem; /* 调整位置 */
  bottom: -2rem; /* 调整位置 */
}

.mic-btn:hover:after {
  width: calc(100% + 8rem); /* 动画效果的宽度 */
  height: calc(100% + 8rem); /* 动画效果的高度 */
  top: -4rem; /* 调整位置 */
  left: -4rem; /* 调整位置 */
  right: -4rem; /* 调整位置 */
  bottom: -4rem; /* 调整位置 */
}

/* ****************************** */
/* ****************************** */
.mic-btn-animate{
  width: 18rem; /* 设置按钮的宽度 */
  height: 18rem; /* 设置按钮的高度 */
  background-color: rgba(14, 51, 70, 0.3);
}

/* 缩放动画 */
.mic-btn-animate {
  animation: scaleUp 1.5s infinite;
}

@keyframes scaleUp {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2); /* Scale up the button */
  }
}
/* 缩放动画 */
.mic-btn-animate2 {
  animation: scaleUp2 1.5s infinite;
}

@keyframes scaleUp2 {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5); /* Scale up the button */
  }
}

/* 调整伪元素 */
.mic-btn-animate::before,
.mic-btn-animate::after {
  width: calc(100% + 8rem);
  height: calc(100% + 8rem);
  top: -4rem;
  left: -4rem;
  right: -4rem;
  bottom: -4rem;
}



.mic-tex-m{
  margin-top: 5rem;
}
.cursor{
  cursor: pointer;
}
.phoneNav-icon-size{
  font-size: 2rem;
}

.volume-loader {
  width: 1.5rem; 
  height: 1.5rem;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(var(--secondary-color) 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
    background-size: 20% 100%, 20% 80%, 20% 60%; /* 初始三条线 */
}
@keyframes l2 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  20% {background-size: 20% 60% ,20% 100%,20% 100%}
  40% {background-size: 20% 80% ,20% 60% ,20% 100%}
  60% {background-size: 20% 100%,20% 80% ,20% 60% }
  80% {background-size: 20% 100%,20% 100%,20% 80% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}
.volume-loader.active{
 animation: l2 1s infinite linear;
}


.api-loader, .btn-loader {
  width: 50px;
  --b: 8px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,var(--secondary-color)) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}
.btn-loader{
  width: 1.5rem;
}
.bar{
  height: 0.2rem;
}
.bar input{
  opacity: 0;
}
.bar2{
  height: 0.2rem;
  /* width: 20rem; */
}

.dot{
  width: 0.4rem;
  height: 0.4rem;
  top: -0.1rem;
}
.dot::before{
  content: ' ';
  position: absolute;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
  left: -0.3rem;
  top: -0.3rem;
  box-shadow: inset 0px 0px 0.1rem var(--secondary-color);
}


.vol-bar input[type= "range"]{
  -webkit-appearance: none;
  appearance: none;
  background:linear-gradient(to right, var(--secondary-color) 40%, #6C757D 40%);
}

.vol-bar input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  background-color: var(--secondary-color);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;

  /* box-shadow: -407px 0 0 400px #f50; emove this line */
  transition: .2s ease-in-out;
}
.vol-bar ::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(110, 171, 201, 0.1)
}

/* //swiper */
swiper-container {
  width: 100%;
}

swiper-slide {
  background-position: center;
  background-size: cover;
  width: 12rem;
  height:fit-content;
}

swiper-slide img {
  display: block;
  width: 100%;
}
swiper-slide .info {
  display: block;
  width: 100%;
}


.playerImg{
  width: 4rem;
}
.p-center{
  top: 10%;
  right: 0;
  left: 0;
}